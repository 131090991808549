import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import profileImg from "../images/profile.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home>
      <div>
        <div className="home__intro--content">
          <h3>Our Mission</h3>
          <p>
          To boldly proclaim the truth of the Word of Yahweh and faithfully prepare His chosen people for the coming age.
          </p>
          <h3>The Podcast</h3>
          <p>
          Our podcast includes a wealth of biblical knowledge from over 40 years of preaching the Kingdom of God.
          </p>
        </div>
        <div>
          <iframe
            src="https://castbox.fm/app/castbox/player/id2996276?v=8.22.10&autoplay=0"
            frameborder="0"
            width="100%"
            
            height="500"
          ></iframe>
        </div>
      </div>

      <img src={profileImg} alt="Dr. McKinney" />
    </Home>
  </Layout>
)

export default IndexPage

const Home = styled.div`
  padding: 1rem;

  img {
    display: none;
  }

  iframe {
      margin-top: 2rem;

  }
  /* height: 100%;
  display: grid;
  

  img {
    width: 75%;
    align-self: end;
    justify-self: end;
    bottom: 0; */
  /* margin-top: 3.5rem; */
  /* } */

  @media (min-width: 768px) {
    padding: 1rem 2rem;

    iframe {
      margin-top: 3rem;
      height: 475px;
    }
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    padding: 1rem;
    height: 100%;
    padding-bottom: 0;
    padding-right: 0;
    grid-area: main;

    iframe {
      margin: 0;
    }


    img {
    display: block;
    /* width: 75%; */
    height: 800px;
    align-self: end;
    justify-self: end;
  }
  }
`
